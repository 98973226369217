
<template>
  <div>
    <div v-if="flights" class="custom-table">
      <table-actions
        :actions="['addBtn', 'search', 'perPage']"
        :addBtnText="$t('flight.add')"
        :addBtnLink="$helper.getAddUrl('flight')"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="flights"
        :sortable="sortable"
        @do-search="setSort"
        @is-finished="isLoading = false"
      >
        <template v-slot:partner="data">
          <router-link :to="$helper.getEditUrl('partner', data.value.partner.numberId)">
            {{ data.value.partner.label }}
          </router-link>
        </template>
        <template v-slot:fromPlace="data">{{ data.value.fromPlace }}</template>
        <template v-slot:toPlace="data">{{ data.value.toPlace }}</template>
        <template v-slot:date="data">{{ $helper.formatDate(data.value.date) }}</template>
        <template v-slot:freePlaces="data">{{ data.value.freePlaces }}</template>
        <template v-slot:price="data">{{ $helper.priceFormat(data.value.price, 2) }}</template>
        <template v-slot:status="data">
          <b-badge :variant="statusClass(data.value.status)">
            {{ $helper.getEnumTranslation("flight_status", data.value.status, $i18n.locale ) }}
          </b-badge>
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn :path="$helper.getEditUrl('flight', data.value.id)" />
            <delete-btn @pressDelete="deleteFlight(data.value['@id'])" />
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-if="pages"
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";

export default {
  components: {
    TableLite,
    TablePagination,
    TableActions,
    EditBtn,
    DeleteBtn,
  },
  mixins: [ListUtils, ResourceUtils],
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      search: "",
      isLoading: false,
      flights: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      partners: [],
      sortable: {
        order: "date",
        sort: "desc",
      },
      columns: [
        {
          label: this.$t("flight.from"),
          field: "fromPlace",
          sortable: true,
        },
        {
          label: this.$t("flight.to"),
          field: "toPlace",
          sortable: true,
        },
        {
          label: this.$t("flight.date"),
          field: "date",
          sortable: true,
        },
        {
          label: this.$t("flight.seating"),
          field: "freePlaces",
          sortable: true,
        },
        {
          label: this.$t("flight.price"),
          field: "price",
          sortable: true,
        },
        {
          label: this.$t("flight.status"),
          field: "status",
          sortable: true,
        },
        {
          label: this.$t("flight.actions"),
          field: "actions",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },
  async created() {
    if(this.userData && this.userData.role === "ROLE_ADMIN") {
      await this.getUserList({ params: { role: "ROLE_PARTNER" } }, this.partners);
      this.columns.unshift(
        {
          label: this.$t("partner.singular"),
          field: "partner",
          sortable: true,
        }
      )
    }
    this.loadFlights();
  },
  methods: {
    loadFlights() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
      };
      params["order[" + this.sortable.order + "]"] = this.sortable.sort;
      if (this.search && this.search.length) {
        params.search = this.search;
      }
      this.$Flights.getCollection({ params }).then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.flights = response.data["hydra:member"];
        if(this.userData && this.userData.role === "ROLE_ADMIN") {
           this.flights.forEach(flight => {
             flight.partner = this.partners.find(partner => partner.id === flight.partner)
           })
        }
      });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadFlights();
    },
    statusClass(status) {
      let className = "inactive";
      if (status === 'PUBLISHED') {
        className = "success";
      } else if (status === 'NOT_PUBLISHED') {
        className = "not-published";
      } else if (status === 'CLOSED') {
        className = "closed";
      } else if (status === 'UNSOLD') {
        className = "unsold";
      } else if (status === 'SOLD') {
        className = "sold";
      } else if (status === 'PARTIALLY_SOLD') {
        className = "partially-sold";
      } else if (status === 'AWAITING_PAYMENT') {
        className = "awaiting-payment";
      } else if (status === 'INVOICED') {
        className = "invoiced";
      } else if (status === 'CANCELED_BY_CLIENT') {
        className = "canceled-by-client";
      } else if (status === 'CANCELED_BY_OUR') {
        className = "canceled-by-our";
      } else if (status === 'CANCELED_DUE_WEATHER') {
        className = "canceled-due-weather";
      }
      return className;
    },
    deleteFlight(url) {
      this.deleteByUrl(
        this.$Flights,
        url,
        this.$t("flight.deleted"),
        null,
        this.loadFlights,
        this.error
      );
    },
    error() {
      this.$emit("clearAction");
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.loadFlights();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadFlights();
    },
    onPageChange() {
      this.loadFlights();
    },
    setSort(offset, limit, order, sort) {
      this.sortable.sort = sort;
      this.sortable.order = order;

      if (this.sortable.order === 'partner') {
          this.sortable.order = 'partner.givenName'
      }

      this.loadFlights();
    },
  },
};
</script>
